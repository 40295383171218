﻿import jQuery from 'jquery';

global.jQuery = jQuery;
global.$ = jQuery;

import 'datatables.net';

require('./semantic.min.js');
$(function () {

    $('.ui.accordion').accordion();
    $('.ui.dropdown').dropdown();
    $('.ui.sidebar')
        .sidebar('setting', 'transition', 'overlay')
        .sidebar('attach events', '.tablet.mobile.only.ui.grid.container .right.menu a:last-child');

});

var table;

//Filtering triggered by dropdowns
jQuery.fn.dataTableExt.afnFiltering.push(
    function (oSettings, aData, iDataIndex) {



        var oTable = $('#qrfList').DataTable();
        var dataRows = oTable.data();

        console.log("begin filtering results for " + dataRows[iDataIndex].name());

        var found = false;

        var serviceName = "";
        var productName = "";

        if ($("#qrfFilter option:selected").val() == ""
            && $("#serviceProductFilter option:selected").val() == ""
            && $("#countyFilter option:selected").val() == "") {
            return true;
        }

        if ($("#qrfFilter option:selected").val() != "") {
            var qrfName = $("#qrfFilter option:selected").val()

            console.log("Selected " + qrfName);

            console.log("Checking against " + dataRows[iDataIndex].name());

            if (dataRows[iDataIndex].name() == qrfName) {
                console.log("Allowed");
                found = true;
            } else {
                return false;
            }
        }

        if ($("#serviceProductFilter option:selected").val() != "") {

            found = false;

            var serviceProductArray = splitServiceProductArray($("#serviceProductFilter option:selected").val());

            if (serviceProductArray[0] == "service") {
                serviceName = serviceProductArray[1];

                console.log("Selected " + serviceName);

                var serviceAreas = dataRows[iDataIndex].serviceAreas();

                for (var i = 0; i < serviceAreas.length; i++) {
                    console.log("Checking Against " + serviceAreas[i].serviceName());
                    if (serviceAreas[i].serviceName() == serviceName) {
                        console.log("Allowed");
                        found = true;
                    }
                }

                if (!found) {
                    return false;
                }
            } else {
                // must be product
                productName = serviceProductArray[1];

                console.log("Selected " + productName);

                var productAreas = dataRows[iDataIndex].productAreas();



                for (var i = 0; i < productAreas.length; i++) {
                    console.log("Checking Against " + productAreas[i].productName());
                    if (productAreas[i].productName() == productName) {
                        console.log("Allowed");
                        found = true;
                    }
                }

                if (!found) {
                    return false;
                }

            }
        }


        if ($("#countyFilter option:selected").val() != "") {

            found = false;

            var countyName = $("#countyFilter option:selected").val()

            console.log("Selected " + countyName);

            // if productName is set, then a product has been selected, in which case we don't care about the county
            if (productName != "") {
                found = true;
            } else {



                var serviceAreas = dataRows[iDataIndex].serviceAreas();

                // checking selected servicename and county against against all service areas

                for (var i = 0; i < serviceAreas.length; i++) {
                    console.log("Checking against " + serviceAreas[i].countyString());

                    if (serviceName == "" || serviceName == serviceAreas[i].serviceName()) {
                        if (serviceAreas[i].countyString().indexOf(countyName) > -1) {
                            found = true;
                        }
                    }
                }

                if (!found) {
                    return false;
                }

            }

        }

        return found;


    }
);


function formatName(d) {

    return d._name + '<div class="expansionOutput"></div>';

}

function redrawMoreContents() {



    table.rows().every(function () {
        // If row has details collapsed

        var tr = $(this.node()).closest('tr');
        var row = table.row(tr);

        if (tr.find('td.details-control').hasClass("shown")) {

            tr.find(".expansionOutput").html(format(row.data()));
            tr.find(".expansionOutput").css("visibility", "visible");


        } else {



            // This row is already open - close it
            tr.find(".expansionOutput").html("");
            tr.find(".expansionOutput").css("visibility", "hidden");


        }




    });


}

// data-tables support
function format(d) {
    // `d` is the original data object for the row

    var qrfName = $("#qrfFilter option:selected").val();

    var serviceProductName = $("#serviceProductFilter option:selected").val();

    var countyName = $("#countyFilter option:selected").val();

    var output = '<table cellpadding="5" cellspacing="0" border="0" width="100%" style="padding:0px">';

    output += '<tr ><td style="padding:0px;vertical-align:top;width:100px;">'

    output += '<a class="moreinfo" style="text-decoration:underline" href="/search/qrf?qrfID=' + d.id() + '&qrfName=' + encodeURIComponent(qrfName) + '&serviceProductName=' + encodeURIComponent(serviceProductName) + '&countyName=' + encodeURIComponent(countyName) + '" >More Info</a>';

    output += '</td>';

    output += '<td style="padding:0px;vertical-align:top">';

    output += '<table cellpadding="5" cellspacing="0" border="0" width="100%" style="padding:0px; margin-top:20px">';

    var foundCountyMatch = false;
    var contacts = [];

    for (var i = 0; i < d._serviceAreas.length; i++) {
        if (serviceProductName == "" || serviceProductName == "service-" + d._serviceAreas[i]._serviceName) {
            foundCountyMatch = false;
            for (var j = 0; j < d._serviceAreas[i]._counties.length; j++) {
                if (countyName == d._serviceAreas[i]._counties[j] || countyName == '') {
                    foundCountyMatch = true;

                }
            }
            if (foundCountyMatch) {

                var countyString = "";

                for (var j = 0; j < d._serviceAreas[i]._counties.length; j++) {
                    countyString += d._serviceAreas[i]._counties[j] + ", ";
                }

                if (d._serviceAreas[i]._counties.length > 0) {
                    countyString = countyString.substring(0, countyString.length - 2);

                }


                output += '<tr style="margin-top:30px;"><td style="width:75%;padding:0px;vertical-align:top"><span style="font-weight:bold">' + d._serviceAreas[i]._serviceName + "</span><br />";
                output += countyString + '</td>';

                contacts = d._serviceAreas[i].serviceContacts();

                output += '<td style="padding:0px;vertical-align:top;width:25%">';

                for (var j = 0; j < contacts.length; j++) {
                    if (j > 0) {
                        output += '<br />';
                    }
                    if (contacts[j].contactName().length > 0) {
                        output += contacts[j].contactName() + '<br />';
                    }
                    if (contacts[j].phoneNumber().length > 0) {
                        output += contacts[j].phoneNumber() + '<br />';
                    }
                    if (contacts[j].emailAddress().length > 0) {
                        output += contacts[j].emailAddress() + '<br />';
                    }
                }

                output += '<br /></td>';
                output += '</tr>';


            }
        }
    }

    for (var i = 0; i < d._productAreas.length; i++) {
        if (serviceProductName == "" || serviceProductName == "product-" + d._productAreas[i]._productName) {
            output += '<tr style="margin-top:10px"><td style="padding:0px;vertical-align:top;width:75%;"><span style="font-weight:bold">' + d._productAreas[i]._productName + "</span><br />";
            output += '</td>';

            contacts = d._productAreas[i].productContacts();

            output += '<td style="padding:0px;vertical-align:top;width:75%;">';

            for (var j = 0; j < contacts.length; j++) {
                if (j > 0) {
                    output += '<br />';
                }
                if (contacts[j].contactName().length > 0) {
                    output += contacts[j].contactName() + '<br />';
                }
                if (contacts[j].phoneNumber().length > 0) {
                    output += contacts[j].phoneNumber() + '<br />';
                }
                if (contacts[j].emailAddress().length > 0) {
                    output += contacts[j].emailAddress() + '<br />';
                }
            }

            output += '<br /></td>';
            output += '</tr>';




        }
    }


    output += '</table></td></tr></table>';


    return output;
}


//$(function () {
//    console.log(typeof addQRFData !== 'undefined' && $.isFunction(addQRFData));
//    if (typeof addQRFData !== 'undefined' && $.isFunction(addQRFData)) {
//        initSearchTable();
//    }
//});


function initSearchTable() {

    table = $('#qrfList').DataTable({
        'columns': [
            {
                'className': 'details-control',
                'orderable': false,
                'data': null,
                'defaultContent': '',
                'searchable': false
            },
            {
                'data': null,
                'render': function (data, type, row) {
                    return formatName(data);



                },
                'searchable': false
            }

        ],
        'order': [[1, 'asc']],
        "dom": 'lrtip',
        "paging": false,
        "infoCallback": function (settings, start, end, max, total, pre) {
            //  if (total > 0) {
            $('#qrfList').show();
            $('.btn-show-all-children').show();
            $('.btn-hide-all-children').show();
            /* } else {
                 $('#qrfList').hide();
                 $('#btn-show-all-children').hide();
                 $('#btn-hide-all-children').hide();
             }*/
        }
    });

    console.log('Resetting serviceProductFilter');

    var tempServiceProductName = $("#serviceProductFilter option:selected").val();

    $('#serviceProductFilter')
        .find('option')
        .remove()
        .end();

    addQRFData(table);

    var options = $("#serviceProductFilter option");                    // Collect options         
    options.detach().sort(function (a, b) {               // Detach from select, then Sort
        var at = $(a).text();
        var bt = $(b).text();
        return (at > bt) ? 1 : ((at < bt) ? -1 : 0);            // Tell the sort function how to order
    });
    options.appendTo("#serviceProductFilter");

    $('#serviceProductFilter')
        .prepend('<option value="">Filter By Service or Product</option>')
        .val('');

    $('#serviceProductFilter').val(tempServiceProductName);

    table.draw();

    // drawing cleanup
    $('#qrfList th.details-control').css("width", "25px");
    $('#qrfList th.details-control').css("padding", "0px");
    $('#qrfList th.details-control').attr("width", "25px");
    $('#qrfList th.details-control').removeClass('details-control');


    $('#qrfList_info').after("<button style=\"margin-top: 0.755em;\" class=\"btn-show-all-children\" type=\"button\">Expand All</button><button style=\"margin-top: 0.755em;\" class= \"btn-hide-all-children\" type = \"button\" >Collapse All</button>");

    // Add event listener for opening and closing details
    $('#qrfList tbody').on('click', 'td.details-control', function () {
        var tr = $(this).closest('tr');
        var row = table.row(tr);




        if (tr.find('td.details-control').hasClass("shown")) {
            // This row is already open - close it
            tr.find(".expansionOutput").html("");
            tr.find(".expansionOutput").css("visibility", "hidden");
            tr.find('td.details-control').removeClass('shown');
            tr.removeClass('shown');

        } else {
            // Open this row
            tr.find(".expansionOutput").html(format(row.data()));
            tr.find(".expansionOutput").css("visibility", "visible");
            tr.find('td.details-control').addClass('shown');
            tr.addClass('shown');

        }



    });

    // Handle click on "Expand All" button
    $('.btn-show-all-children').on('click', function () {
        // Enumerate all rows
        table.rows().every(function () {
            // If row has details collapsed

            if (!$(this.node()).find('td.details-control').hasClass("shown")) {

                // Open this row
                $(this.node()).find('td.details-control').click();


            }
        });
    });

    // Handle click on "Collapse All" button
    $('.btn-hide-all-children').on('click', function () {
        // Enumerate all rows
        table.rows().every(function () {
            // If row has details expanded

            // Collapse row details
            if ($(this.node()).find('td.details-control').hasClass("shown")) {


                $(this.node()).find('td.details-control').click();
            }
        });
    });

    $('#qrfFilter').change(function () {

        console.log("qrfFilter changed");

        var qrfName = $("#qrfFilter option:selected").val();

        console.log("Filtering by  " + qrfName);

        /*
        $('#qrfFilter')
            .find("option[value!='" + qrfName + "']")
            .remove()
            .end();*/

        updateServiceProductSelect();

        updateCountySelect();

        redrawMoreContents();

        table.draw();
    });

    $('#resetFilter').click(function () {

        console.log("Filter reset");

        $('#qrfFilter')
            .find('option')
            .remove()
            .end();

        $('#qrfFilter')
            .prepend('<option value="">Filter by Oregon Forward Contractor</option>')
            .val('');

        $('#serviceProductFilter')
            .find('option')
            .remove()
            .end();

        $('#serviceProductFilter')
            .prepend('<option value="">Filter By Service or Product</option>')
            .val('');

        $('#countyFilter')
            .find('option')
            .remove()
            .end();

        $('#countyFilter')
            .prepend('<option value="">Filter By County</option>')
            .val('');

        updateQrfSelect();

        updateServiceProductSelect();

        updateCountySelect();

        redrawMoreContents();



        table.draw();

        // collapse all

        table.rows().every(function () {
            // If row has details expanded

            // Collapse row details
            if ($(this.node()).find('td.details-control').hasClass("shown")) {


                $(this.node()).find('td.details-control').click();
            }
        });
    });

    $('#serviceProductFilter').change(function () {
        console.log("serviceProductFilter changed");

        var serviceProductName = $("#serviceProductFilter option:selected").val();

        console.log("Filtering by  " + serviceProductName);

        /*  $('#serviceProductFilter')
              .find("option[value!='" + serviceProductName + "']")
              .remove()
              .end();
              */
        updateQrfSelect();

        updateCountySelect();

        redrawMoreContents();

        table.draw();
    });
    /*
    $('#resetServiceProductFilter').click(function () {

        console.log("serviceProductFilter reset");

        $('#serviceProductFilter')
            .find('option')
            .remove()
            .end();

        $('#serviceProductFilter')
            .prepend('<option value="">Filter By Service or Product</option>')
            .val('');

        updateQrfSelect();

        updateServiceProductSelect();

        updateCountySelect();

        redrawMoreContents();

        table.draw();
    });*/

    $('#countyFilter').change(function () {
        console.log("countyFilter changed");

        var countyName = $("#countyFilter option:selected").val();

        console.log("Filtering by  " + countyName);
        /*
        $('#countyFilter')
            .find("option[value!='" + countyName + "']")
            .remove()
            .end();*/

        updateQrfSelect();

        updateServiceProductSelect();

        redrawMoreContents();

        table.draw();
    });

    /*
    $('#resetCountyFilter').click(function () {

        console.log("countyFilter reset");

        $('#countyFilter')
            .find('option')
            .remove()
            .end();

        $('#countyFilter')
            .prepend('<option value="">Filter By County</option>')
            .val('');

        updateQrfSelect();

        updateServiceProductSelect();

        updateCountySelect();

        redrawMoreContents();

        table.draw();
    });*/

}
global.initSearchTable = initSearchTable;
// updates the QrfSelect Dropdown based on the state of all three dropdowns (should be called after onchange or reset event)
function updateQrfSelect() {

    var qrfName = $("#qrfFilter option:selected").val();

    var serviceProductName = $("#serviceProductFilter option:selected").val();

    var countyName = $("#countyFilter option:selected").val();

    // we only update the dropdown if something has not already been selected in it

    console.log("No QRF selected");
    // remove all options
    $('#qrfFilter')
        .find('option')
        .remove()
        .end();

    // add back in the options that are possible given the state of qrf and county selects
    table.rows().every(function () {
        console.log("updateQrfSelect: Looking at row for " + this.data()._name);
        for (var i = 0; i < this.data()._serviceAreas.length; i++) {
            if (serviceProductName == "" || serviceProductName == "service-" + this.data()._serviceAreas[i]._serviceName) {
                console.log("updateQrfSelect: Looking at service " + this.data()._serviceAreas[i]._serviceName);
                for (var j = 0; j < this.data()._serviceAreas[i]._counties.length; j++) {
                    if (countyName == this.data()._serviceAreas[i]._counties[j] || countyName == '') {
                        console.log("updateQrfSelect: Looking at county " + this.data()._serviceAreas[i]._counties[j]);
                        updateFilterSelect("qrfFilter", this.data()._name, this.data()._name);
                    }
                }
            }
        }
        for (var i = 0; i < this.data()._productAreas.length; i++) {
            if (serviceProductName == "" || serviceProductName == "product-" + this.data()._productAreas[i]._productName) {
                console.log("updateQrfSelect: Looking at product " + this.data()._productAreas[i]._productName);
                updateFilterSelect("qrfFilter", this.data()._name, this.data()._name);
            }
        }
    });

    sortMeBy("value", "#qrfFilter", "option", "asc");

    // add back default option
    $('#qrfFilter')
        .prepend('<option value="">Filter by Oregon Forward Contractor</option>')
        .val('');

    $('#qrfFilter option[value=\"' + qrfName + '\"]').prop("selected", true);
}
global.updateQrfSelect = updateQrfSelect;
// updates the ServiceProductSelect Dropdown based on the state of all three dropdowns (should be called after onchange or reset event)
function updateServiceProductSelect() {

    console.log('updateServiceProductSelect called');

    var qrfName = $("#qrfFilter option:selected").val();

    var serviceProductName = $("#serviceProductFilter option:selected").val();

    var countyName = $("#countyFilter option:selected").val();

    // we only update the dropdown if something has not already been selected in it
    //  if (serviceProductName == "") {

    // remove all options
    $('#serviceProductFilter')
        .find('option')
        .remove()
        .end();

    // add back in the options that are possible given the state of qrf and county selects
    table.rows().every(function () {
        console.log("updateServiceProductSelect: Looking at row for " + this.data()._name);
        if (this.data()._name == qrfName || qrfName == "") {
            console.log("updateServiceProductSelect: Looking at " + this.data()._name);
            for (var i = 0; i < this.data()._serviceAreas.length; i++) {
                for (var j = 0; j < this.data()._serviceAreas[i]._counties.length; j++) {
                    if (countyName == this.data()._serviceAreas[i]._counties[j] || countyName == '') {
                        console.log("updateServiceProductSelect: Looking at county " + this.data()._serviceAreas[i]._counties[j]);
                        updateFilterSelect("serviceProductFilter", "service-" + this.data()._serviceAreas[i]._serviceName, this.data()._serviceAreas[i]._serviceName);
                    }
                }
            }
            for (var i = 0; i < this.data()._productAreas.length; i++) {
                updateFilterSelect("serviceProductFilter", "product-" + this.data()._productAreas[i]._productName, this.data()._productAreas[i]._productName);
            }
        }
    });

    sortServiceBy("value", "#serviceProductFilter", "option", "asc");

    // add back default option
    $('#serviceProductFilter')
        .prepend('<option value="">Filter By Service or Product</option>')
        .val('');

    $('#serviceProductFilter option[value=\"' + serviceProductName + '\"]').prop("selected", true);
    // }
}
global.updateServiceProductSelect = updateServiceProductSelect;
// updates the CountySelect Dropdown based on the state of all three dropdowns (should be called after onchange or reset event)
function updateCountySelect() {

    var qrfName = $("#qrfFilter option:selected").val();

    var serviceProductName = $("#serviceProductFilter option:selected").val();

    var countyName = $("#countyFilter option:selected").val();

    // we only update the dropdown if something has not already been selected in it
    // if (countyName == "") {

    // remove all options
    $('#countyFilter')
        .find('option')
        .remove()
        .end();

    // add back in the options that are possible given the state of qrf and product/service selects
    table.rows().every(function () {
        console.log("updateCountySelect: Looking at row for " + this.data()._name);
        if (this.data()._name == qrfName || qrfName == "") {
            console.log("updateCountySelect: Looking at " + this.data()._name);
            for (var i = 0; i < this.data()._serviceAreas.length; i++) {
                if (serviceProductName == "" || serviceProductName == "service-" + this.data()._serviceAreas[i]._serviceName) {
                    console.log("updateCountySelect: Looking at service " + this.data()._serviceAreas[i]._serviceName);
                    for (var j = 0; j < this.data()._serviceAreas[i]._counties.length; j++) {
                        updateFilterSelect("countyFilter", this.data()._serviceAreas[i]._counties[j], this.data()._serviceAreas[i]._counties[j]);
                    }
                }
            }

            for (var i = 0; i < this.data()._productAreas.length; i++) {
                if (serviceProductName == "" || serviceProductName == "product-" + this.data()._productAreas[i]._productName) {
                    // TODO - Add every county right now because this qrf has products which are available every county
                    fillCountyDropdown();
                }
            }


        }
    });

    sortMeBy("value", "#countyFilter", "option", "asc");

    // add back default option
    $('#countyFilter')
        .prepend('<option value="">Filter By County</option>')
        .val('');

    $('#countyFilter option[value=\"' + countyName + '\"]').prop("selected", true);



}
global.updateCountySelect = updateCountySelect;
function fillCountyDropdown() {

    $.each(fullCountyList, function (index, value) {
        updateFilterSelect("countyFilter", value, value);
    });


    sortMeBy("value", "#countyFilter", "option", "asc");


}
global.fillCountyDropdown = fillCountyDropdown;
function splitServiceProductArray(serviceProductString) {

    var serviceProductArray = [];


    if (serviceProductString.replace("service-", "").length == serviceProductString.length) {
        // string starts with product-
        serviceProductArray[0] = "product";
        serviceProductArray[1] = serviceProductString.replace("product-", "");
    } else {
        // string starts with service-
        serviceProductArray[0] = "service";
        serviceProductArray[1] = serviceProductString.replace("service-", "");
    }


    return serviceProductArray;

}
global.splitServiceProductArray = splitServiceProductArray;
function QRF(id, name, url, headquartersAddress, phoneNumber, faxNumber, ceo, ceoEmail) {
    this._id = id;
    this._name = name;
    this._url = url;
    this._headquartersAddress = headquartersAddress;
    this._phoneNumber = phoneNumber;
    this._faxNumber = faxNumber;
    this._ceo = ceo;
    this._ceoEmail = ceoEmail;


    this._serviceAreas = [];
    this._productAreas = [];

    this.name = function () {
        return this._name;
    };

    this.id = function () {
        return this._id;
    }

    this.url = function () {
        return this._url;
    };

    this.headquartersAddress = function () {
        return this._headquartersAddress;
    }

    this.phoneNumber = function () {
        return this._phoneNumber;
    }

    this.faxNumber = function () {
        return this._faxNumber;
    }

    this.ceo = function () {
        return this._ceo;
    }

    this.ceoEmail = function () {
        return this._ceoEmail;
    }

    this.serviceAreas = function () {
        return this._serviceAreas;
    }

    this.productAreas = function () {
        return this._productAreas;
    }

    this.addServiceArea = function (serviceArea) {
        this._serviceAreas.push(serviceArea);
    }

    this.addProductArea = function (productArea) {
        this._productAreas.push(productArea);
    }
}
global.QRF = QRF;

function sortMeBy(arg, sel, elem, order) {
    var $selector = $(sel),
        $element = $selector.children(elem);

    $element.sort(function (a, b) {
        var an = a.getAttribute(arg),
            bn = b.getAttribute(arg);
        if (order == "asc") {
            if (an > bn)
                return 1;
            if (an < bn)
                return -1;
        } else if (order == "desc") {
            if (an < bn)
                return 1;
            if (an > bn)
                return -1;
        }
        return 0;
    });
    $element.detach().appendTo($selector);
}
global.sortMeBy = sortMeBy;
function sortServiceBy(arg, sel, elem, order) {
    var $selector = $(sel),
        $element = $selector.children(elem);

    $element.sort(function (a, b) {
        var an = splitServiceProductArray(a.getAttribute(arg))[1];
        var bn = splitServiceProductArray(b.getAttribute(arg))[1];
        if (order == "asc") {
            if (an > bn)
                return 1;
            if (an < bn)
                return -1;
        } else if (order == "desc") {
            if (an < bn)
                return 1;
            if (an > bn)
                return -1;
        }
        return 0;
    });
    $element.detach().appendTo($selector);
}
global.sortServiceBy = sortServiceBy;
function ServiceArea(serviceName, dba) {
    this._serviceName = serviceName;

    this._dba = dba;

    this._contacts = [];
    this._counties = [];

    this.serviceName = function () {
        return this._serviceName;
    }

    this.countyString = function () {

        if (this._counties != null) {
            return this._counties.join(',');
        }
    }


    this.dba = function () {
        return this._dba;
    }

    this.serviceContacts = function () {
        return this._contacts;
    }

    this.addContact = function (contact) {
        this._contacts.push(contact);
    }

    this.addServiceCounty = function (countyString) {
        this._counties.push(countyString);
    }
}
global.ServiceArea = ServiceArea;
function ProductArea(productName, dba) {
    this._productName = productName;
    this._dba = dba;

    this._contacts = [];

    this.productName = function () {
        return this._productName;
    }


    this.dba = function () {
        return this._dba;
    }

    this.productContacts = function () {
        return this._contacts;
    }

    this.addContact = function (contact) {
        this._contacts.push(contact);
    }
}
global.ProductArea = ProductArea;
function Contact(contactName, phoneNumber, emailAddress) {
    this._contactName = contactName;
    this._phoneNumber = phoneNumber;
    this._emailAddress = emailAddress;

    this.contactName = function () {
        return this._contactName;
    }

    this.phoneNumber = function () {
        return this._phoneNumber;
    }

    this.emailAddress = function () {
        return this._emailAddress;
    }

}
global.Contact = Contact;
function updateFilterSelect(selectListName, optionValue, optionText) {


    if ($("#" + selectListName + " option[value='" + optionValue + "']").length == 0) {
        console.log("Added " + optionText);
        var selectListObj = $("#" + selectListName);

        selectListObj.append($('<option></option>').val(optionValue).html(optionText));

    }

}
global.updateFilterSelect = updateFilterSelect;